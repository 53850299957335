.container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 8px;
    gap: 6px;
}

.dot {
    width: 6px;
    height: 6px;
    background-color: var(--mid-grey);
    border-radius: 50%;
    transition: background-color 0.3s ease;
}

.dot.selected {
    background-color: var(--success-green);
    width: 8px;
    height: 8px;
}