.container {
    display: flex;
    align-items: center;
    flex-direction: row;
    text-align: left;
    width: 100%;
    height: unset;
    border-bottom: 1px solid rgb(182, 182, 182);
    cursor: pointer;
    padding: 10px;
}

.container:hover {
    background-color: var(--light-grey);
    border-radius: 0;
}

.productDetails {
    max-width: 90%;
}