.container {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 33.33%; /* 3:1 Aspect Ratio */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 12px;
    overflow: hidden;
}

/* Fallback effect when image fails to load */
.fallback {
    background: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
    background-size: 200% 100%;
    animation: shimmer 2s infinite linear;
}

/* Shimmer animation */
@keyframes shimmer {
    0% { background-position: -200% 0; }
    100% { background-position: 200% 0; }
}

/* Dark Gradient Overlay for Readability */
.hasShadow::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.7));
}

/* Text Styling Inside Header */
.innerContainer {
    position: absolute;
    bottom: 20px;
    left: 20px;
    color: white;
    font-size: 24px;
    font-weight: bold;
    text-shadow: 1px 1px 6px rgba(0, 0, 0, 0.5);
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
    .container {
        padding-top: 50%;
    }
    .innerContainer {
        font-size: 18px;
    }
}