:root {
  --nav-height: 82px;
  --fixed-nav-zindex: 1030;
  --modal-zindex: 1100;
  --loader-zindex: 2000;
  --menu-zindex: 200;
  --mobile-nav-height: 62px;
  --white: #fff;
  --black: #000;
  --primary: #e9a935;
  --secondary: #276354;
  --primary-gradient: linear-gradient(to right, var(--secondary), var(--primary));
  --light-primary: #f2d298;
  --grey: #7e7e7e;
  --mid-grey: #ccc;
  --light-grey: #ededed;
  --dark-grey: #454545;
  --very-light-grey: #f5f8fa;
  --translucent:  rgba(0, 0, 0, 0.67);
  --active-screen-width: 1164px;
  --error-red: #ffb5b5;
  --dark-error-red: #e04747;
  --light-error-red: #f8dbdb;
  --warning-yellow: #feee94;
  --dark-warning-yellow: #df9824;
  --light-success-green: #cdf3d1;
  --success-green: #7ddc70;
  --dark-success-green: #208812;
  --info-blue: #0440c0;
  --light-info-blue: #bfd3ff;
  --link-blue: #1a73e8;
  --credit-green: #3aa52d;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 18px;;
}

html {
  overflow-x: hidden;  
}
html, body, #root {
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
}

*, ::after, ::before {
  box-sizing: inherit
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

main, .main {
  min-height: 100vh;
  max-width: 100vw
}

/* framework */

.width-fit-content {
  width: fit-content;
}
.width-100pct {
  width: 100%
}

.max-width-100vw {
  max-width: 100vw;
}

.height-fit-content {
  height: fit-content!important;
}

.height-100pct {
  height: 100%;
}

.min-height-100vh {
  min-height: 100vh;
}

.max-height-100pct {
  max-height: 100%;
}

.max-height-90vh {
  max-height: 90vh;
}

.max-height-80vh {
  max-height: 80vh;
}

.max-height-200px {
  max-height: 200px;
}

.overflow-y-auto {
  overflow-y: auto
}

.margin-auto {
  margin: auto
}

.display-block {
  display: block;
}

.display-flex {
  display: flex;
}

.flex-1 {
  flex: 1
}

.flex-wrap {
  flex-wrap: wrap
}

.gap-2 {
  gap: 0.5rem
}
.flex-direction-column {
  flex-direction: column;
}
.align-items-center {
  align-items: center
}

.justify-content-center {
  justify-content: center;
}

.justify-content-space-between {
  justify-content: space-between;
}
.justify-content-space-around {
  justify-content: space-around;
}

.text-align-left {
  text-align: left
}
.text-align-center {
  text-align: center
}
.text-align-justify {
  text-align: justify
}

.text-align-right {
  text-align: right
}

.text-decoration-none {
  text-decoration: none;
}
.padding-1em {
  padding: 1em
}
.padding-05em {
  padding: 0.5em
}

.padding-left-05em {
  padding-left: 0.5em
}

.padding-right-05em {
  padding-right: 0.5em
}

.padding-top-05em {
  padding-top: 0.5em
}
.padding-bottom-05em {
  padding-bottom: 0.5em
}

.padding-5px {
  padding: 5px!important
}

.padding-10px {
  padding: 10px
}

.padding-1pct, .modalOverlay.padding-1pct {
  padding: 1%
}
.margin-left-1em {
  margin-left: 1em;
}
.margin-right-1em {
  margin-right: 1em;
}
.margin-top-1em {
  margin-top: 1em;
}
.margin-bottom-1em {
  margin-bottom: 1em;
}

.margin-left-05em {
  margin-left: 0.5em;
}
.margin-right-05em {
  margin-right: 0.5em;
}
.margin-top-05em {
  margin-top: 0.5em;
}
.margin-bottom-05em {
  margin-bottom: 0.5em;
}

.margin-left-5px {
  margin-left: 5px;
}

.margin-top-5px {
  margin-top: 5px;
}

.text-transform-uppercase {
  text-transform: uppercase;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-200 {
  font-weight: 200;
}

.font-size-32px, .font-size-32px svg {
  font-size: 32px;
}

.font-size-24px, .font-size-24px svg {
  font-size: 24px!important
}

.font-size-14px, .font-size-14px svg {
  font-size: 14px;
}

.font-size-12px, .font-size-12px svg {
  font-size: 12px;
}

.font-size-11px, .font-size-11px svg{
  font-size: 11px;
}

.font-size-10px, .font-size-10px svg{
  font-size: 10px;
}

.color-white {
  color: var(--white)
}

.color-grey {
  color: var(--grey)
} 

.color-black {
  color: var(--black)
} 

.color-secondary {
  color: var(--secondary)
}

.background-color-light-grey {
  background-color: var(--light-grey)
} 

.background-color-transparent {
  background-color: transparent
} 

.border-radius-8px {
  border-radius: 8px;
}

.white-space-nowrap { 
  white-space: nowrap;
}

.cursor-pointer {
  cursor: pointer
}

.list-style-lower-alpha {
  list-style: lower-alpha
}

.list-style-lower-roman {
  list-style: lower-roman
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.top-0 {
  top: 0
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0
}

.right-0 {
  right: 0;
}

.top-5px {
  top: 5px
}

.bottom-5px {
  bottom: 5px;
}

.left-5px {
  left: 5px
}

.right-5px {
  right: 5px;
}

.inset-0 {
  inset: 0;
}

.transition-opacity {
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
} 

.transition-duration-500 {
  transition-duration: 500;
} 

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 100;
}
.display-none {
  display: none;
}
/* common usecases */
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* navigation bar styles */

.activeScreenSize {
  width: 100%;
  max-width: var(--active-screen-width);
}
.topBar {
  position: fixed;
  top: 0;
  width: 100%;
  height: var(--nav-height);
  z-index: var(--fixed-nav-zindex);
}

.navContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: auto;
  max-width: var(--active-screen-width);
  position: relative;
  align-items: center;
}

.navHeader {
  height: var(--nav-height);
  display: flex;
  align-items: center;
  justify-content: center;
}

.hover-background-light-grey:hover {
  background-color: var(--light-grey);
}

.hover-background-primary:hover {
  background-color: var(--primary);
}

@media (max-width: 1060px) {
  .navContainer {
    padding: 0 0.5em; 
  }
}

.navLogo {
  height: 65px;
  width: auto;
}

.navLogo.small {
  height: 35px;
  width: auto;
}

.navMenu {
  display: flex;
  align-items: center;
}

.navMain {
  display: flex;
  height: 100%;
  gap: 20px
}

.navMain li {
  align-items: center;
  height: 100%;
  list-style: none;
}
@media(min-width: 700px){
  .navMenu {
    flex-direction: row;
  }
}


.columnLayout {
  max-width: 1016px;
  margin: 0 auto;
  padding: 0 64px;
}

@media(max-width: 1060px){
  .columnLayout {
    width: 100%;
    max-width: 100vw;
    padding: 0 24px
  }
}

@media(max-width: 700px){
  .columnLayout {
    padding: 0 5px
  }
}

.navSidebar {
  position: static;
  height: 100vh;
  width: 240px;
  display: flex;
  flex-direction: column;  
}

.navSidebarHeader {
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navSidebarBody ul {
  margin-top: 0;
}
.navSidebarBody li {
  align-items: center;
  height: 100%;
  list-style: none;
}

.sideMenuItem {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-items: center;
  gap: 16px;
  padding: 12px 16px;

}

.sideMenuItem:hover {
  transform: scale(1.1);
}
.sideMenuItem.active {
  background: var(--light-grey);
  color: var(--secondary);
  font-weight: 700;
  border-radius: 8px;
}

a.sideMenuItem {
  text-decoration: none;
  color: var(--black);
  cursor: pointer;
}

/* mobile navigation styles*/

.mobileNavigationBar {
  padding: 7px 5px;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: var(--mobile-nav-height);
  background: var(--white);
  z-index: var(--fixed-nav-zindex);
}

.mobileNavigationBar ul {
  margin: 0;
  padding: 0 5px;
  gap: 5px;
  display: flex;
  justify-content: space-around;
}
.mobileNavItem {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 1 82px;
  background: var(--white);
  list-style: none;
}

.mobileNavItem:hover {
  transform: scale(1.1)
}

.mobileNavLink.active {
  color: var(--secondary);
  font-weight: 700;
  background: var(--light-grey);
  border-radius: 8px;
}
.mobileNavLink {
  text-decoration: none;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 0 0 100%;
  padding: 2px;
}

.mobileOnly {
  display: none
}

@media(max-width: 1060px){
  .mobileOnly{
    display: block
  }

  .webOnly {
    display: none;
  }
}

/* hero styles */

.section {
  padding-top: 80px;
  padding-bottom: 80px;
}

/* button styles */

.button {
  font-size: 16px;
  min-height: 32px;
  height: 32px;
  border-radius: 8px;
  background-color: var(--white);
  /* color: var(--white); */
  border-width: 0.5px;
  border-color: var(--very-light-grey);
  outline: none;
  padding: 2px 1em;
  cursor: pointer;
  white-space: nowrap;
  -webkit-tap-highlight-color: transparent;
}

.button:hover, button:active {
  filter: brightness(1.3);
}
.button.padding-med {
  padding: 16px;
  min-height: unset;
  height: unset;
}

.button.small {
  font-size: 12px;
  padding: 3px 8px;
  min-height: unset;
  height: fit-content;
}

.button.primary, .primary {
  background-color: var(--primary);
}

.button.secondary, .secondary, .accordionSummary.secondary {
  background-color: var(--secondary);
  color: var(--white)
}

.secondary svg {
  color: var(--white)
}

.button.gradient, .gradient {
  background-image: var(--primary-gradient);
  color: var(--white)
}

.button.transparent {
  border: none;
  background-color: transparent;
}

.button.white {
  background-color: var(--white);
}

.button.outline {
  border-color: var(--secondary);
  color: var(--secondary);
  background-color: white;
}

.button.transparent:hover {
  background: rgba(0, 0, 0, 0.096)
}
.button a, .button a:visited {
  text-decoration: none;
  color: inherit;
}

.button.icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-left svg {
    margin-right: 5px;
}

.icon-right svg {
  margin-left: 5px;
}

/* footer styles */

.bottom {
  display: flex;
  justify-content: space-between;
  padding: 1em;
  margin-right: 1em;
}

.bottom img {
  height: 25px;
  width: auto
}

.spaced {
  margin-bottom: 0.5em
}
.blocks {
  display: flex;
  flex-wrap: wrap;
}
.stack {
  padding: 2em 1em;
  display: flex;
  flex: 1;
  flex-direction: column;
  /* width: 300px; */
  margin: 0 1em 1em 0;
  background: #ffffff2e;
  border-radius: 0px;
}

.stack > div {
  margin-bottom: 5px;
}

/* forms */
.input, .textarea, .select{
  padding: 12px 16px;
  width: 100%;
  border: 0.5px solid var(--grey);
  background: var( --very-light-grey);
  border-radius: 8px;
  font-size: 16px;
}

.error input, .error select, .error textarea {
  border-color: var(--dark-error-red);
}

.material-input {
  width: auto;
  padding: 8px 0;
  border: none;
  border-bottom: 2px solid #ccc;
  font-size: 16px;
  outline: none;
  background: transparent;
  transition: border-bottom-color 0.3s ease-in-out;
}

.material-input:focus {
  border-bottom-color: black; /* Material Design primary color */
}

.errorText {
  font-size: 14px;
  color: var(--dark-error-red)
}
.errorText::first-letter {
  text-transform: capitalize
}

.label {
  margin-bottom: 5px;
  font-size: 14px;
}

.formContainer {
  display: flex;
  flex-direction: column;
  max-height: 80vh; 
  overflow: hidden; 
}

.formHeader {
  flex-shrink: 0;
  padding: 5px;
}
.formBody {
  flex-grow: 1;
  overflow-y: auto;
  padding: 5px;
}
.formFooter {
  flex-shrink: 0;
  padding: 5px;
  background-color: var(--light-grey); 
  border-radius: 8px;
  border-top: 1px solid #ddd; 
  position: sticky;
  bottom: 0;
  z-index: 10; 
}
/* headings */

.h1 {
  font-size: 30px;
  font-weight: 600;
}
.h2 {
  font-size: 26px;
  font-weight: 600;
}

.h3 {
  font-size: 20px;
  font-weight: 700;
}

.h4 {
  font-size: 0.8em;
  font-weight: 600;
}

.a {
  text-decoration: underline;
  color: var(--link-blue);
  cursor: pointer;
}

.a:hover {
  font-weight: 600;
}

/* currency */

.amount {
  font-family: monospace;
  font-size: 18px;
}
.amount em, .small {
  font-size: 0.75em;
  font-style: normal;
}

.amount label {
  white-space: nowrap;
}

.amount input {
  font-family: monospace;
  font-size: 18px;
  padding: 10px 16px;
}
.amount.big {
  font-size: 60px;
}

@media (max-width: 700px){
  .amount.big {
    font-size: 48px;
  }
  .amount.big input {
    width: 200px;
    font-size: 48px;
  }

}

/* status badges */

.badge {
  padding: 3px 10px;
  border-radius: 20px;
  border: 1px solid var(--black);
  display: inline-block;
  text-transform: capitalize;
  width: fit-content;
  white-space: nowrap;
  font-size: 12px;
}

.badge.small {
  font-size: 10px;
  padding: 1px 10px;
}
.badge.success, .success {
  background: var(--success-green);
  border: 1px solid var(--dark-success-green);
}

.badge.error {
  color: var(--dark-error-red);
  background: var(--light-error-red);
  border: 1px solid var(--dark-error-red);
}

.badge.warning, .warning {
  background: var(--warning-yellow);
  border: 1px solid var(--dark-warning-yellow);
}

.badge.info, .info {
  color: var(--info-blue);
  background: var(--light-info-blue);
  border: 1px solid var(--info-blue);
}

.badge.dark {
  color: white;
  background: var(--dark-grey);
  border: 1px solid var(--dark-grey);
}

/* icon */
.iconContainer {
  height: 30px;
  width: 30px;
  min-width: 30px;
  min-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  cursor: pointer;
  background: var(--white);
}

.iconContainer.transparent {
  background: transparent;
}
.iconContainer:hover {
  background: rgba(0, 0, 0, 0.082);
}

.innerIconContainer {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white)
}

.iconCircle {
  border: 1px solid;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: 56px;
  width: 56px;
  cursor: pointer;
}

/* modal */
.modalContent {
  position: relative;
  border: 1px outset rgb(204, 204, 204);
  background: var(--white);
  padding: 20px;
  height: 100%;
  width: fit-content;
  border-radius: 16px;
  outline: none;
  max-width: 100%;
  overflow-y: auto
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color:var(--translucent);
  z-index: var(--modal-zindex);
  padding: 1rem;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}

@media(max-width: 700px){
  .modalContent {
      width: 100%;
  }
  .modalOverlay {
      padding: 5px;
  }
}

/* card */
.card {
  border: 1px outset var(--light-grey);
  border-radius: 8px;
  padding: 1em;
  background-color: var(--white);
}

.card.transparent {
  background-color: transparent;
}

.card-shadow {
  box-shadow: 0px 0px 5px 4px var(--light-grey);
}
.display-width-400px {
  min-width: 400px;
  width: 400px;
  max-width: 100%;
}

@media(max-width: 700px){
  .display-width-400px {
      min-width: unset;
      width: 100%;
  }   
}

/** action text interaction **/

.actionText {
  cursor: copy;
}
.actionText:hover{
  background-color: var(--light-primary);
}
.actionText:active {
  background-color: var(--info-blue);
  color: var(--white)
}

/** payee products **/
.payeeProduct {
  width: 125px;
  height: auto;
  max-height: 250px;
  border-radius: 8px;
  box-shadow: 3px 3px 3px 0px grey;
}

.productImageContainer {
  width: 80px;
  height: 80px;
  min-width: 80px;
  max-width: 80px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden; /* Crop content */
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid white;
} 

.productImageContainer > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  transition: transform 0.3s ease; /* Smooth hover effect */
} 

.productImageContainer:hover img {
  transform: scale(1.05); /* Slight zoom on hover */
}

.productContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
  height: 200px;
  cursor: pointer;
  text-align: center;
  padding: 10px;
}

.productContainer:hover {
  background-color: var(--light-grey);
  border-radius: 8px;
}

.productContainer.row {
  flex-direction: row;
  text-align: left;
  width: 100%;
  height: unset;
  border-bottom: 1px solid rgb(182, 182, 182);
}

.productDetailsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1
}

.productDetailsContainer.row {
  flex-direction: row;
}

.productName {
  font-size: 16px;
  font-weight: 600;
  color: var(--dark-grey);
}

.productDescription {
  color: var(--grey);
  font-size: 12px;
}

.productDescription, .productName {
  /* width: 180px; */
  white-space: nowrap; /* Prevent text wrapping to the next line */
  overflow: hidden; /* Hide overflow content */
  text-overflow: ellipsis; /* Add ellipsis to indicate truncated content */
  
}

.productVariantPickerContainer {
  overflow-x: auto;
  width: 450px;
  max-width: 100%;
}



@media (max-width: 450px) {
  .productName {
      white-space: unset
  }
  .productContainer.row {
      margin-left: 10px;
  }
  .productContainer.row .productDescription, .productContainer.row .productName  {
      width: 150px
  }
}

@media (max-width: 400px) {
  .container.row .description, .container.row .name  {
      width: 120px
  }
}

/** legal **/
.legal {
  text-align: justify;
}

.legal .h2 {
  margin: 1em 0
}

.legal li {
  margin-bottom: 0.5em;
}

/** accordion **/
.accordion:first-of-type .MuiAccordionSummary-root {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}


.accordion:last-of-type .MuiAccordionSummary-root {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

/** video **/

.video {
  width: 100%;
  max-width: 600px;
  min-height: 300px;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #000;
  object-fit: cover;
}

@media (max-width: 600px) {
  .video {
    border-radius: 5px;
    min-height: 70vh;
  }
}

/** catalog **/

.fixed-bottom-bar {
  text-align: center;
  padding: 1em;
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0; 
  background-color: var(--white);
  border-top: 1px solid var(--grey);
}

.spacer {
  height: 150px;
}

@media (max-width: 700px) {
  .spacer {
    height: 220px;
  }
}