.container {
    width: 100%;
    max-width: 95vw;
    height: unset;
    border-bottom: 1px solid rgb(182, 182, 182);
    padding: 10px;

}

.container:hover {
    background-color: var(--light-grey);
    border-radius: 8px;
}

.productContainer {
    display: flex;
    align-items: center;
    cursor: pointer;
    flex: 1;
    flex-direction: row;
    text-align: left;
    width: 100%;
    height: unset;
}
.detailsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    flex-direction: row;
}

.image {
    margin-bottom: 3px;
}
.name {
    font-size: 16px;
    font-weight: 600;
    color: var(--dark-grey)
}

.description {
    color: var(--grey);
    font-size: 12px;
}

.description, .name {
    width: 180px;
    white-space: nowrap; /* Prevent text wrapping to the next line */
    overflow: hidden; /* Hide overflow content */
    text-overflow: ellipsis; /* Add ellipsis to indicate truncated content */
    max-width: 50vw;
    width: fit-content
}

/* row display */

.productContainer:hover {
    border-radius: 0;
}

.productContainer .textContainer {
    margin-left: 20px;
}

.productVariantsContainer {
    max-width: 100%;
    overflow-x: auto;
    margin-top: 3px;
}
@media (min-width: 1400px) {
    .productContainer .description, .productContainer .name {
        max-width: 600px;
    }
}

@media (max-width: 450px) {
    .name {
        white-space: unset
    }
    .productContainer .textContainer {
        margin-left: 10px;
    }
    .productContainer .description, .productContainer .name  {
        width: 150px
    }
}

@media (max-width: 400px) {
    .productContainer .description, .productContainer .name  {
        width: 120px
    }
}
