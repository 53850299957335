.image {
    margin-bottom: 3px;
}
.name {
    font-size: 16px;
    font-weight: 600;
    color: var(--dark-grey)
}

.description {
    color: var(--grey);
    font-size: 12px;
}

.nowrap .description, .nowrap .name {
    white-space: nowrap; /* Prevent text wrapping to the next line */
}
.description, .name {
    overflow: hidden; /* Hide overflow content */
    text-overflow: ellipsis; /* Add ellipsis to indicate truncated content */
    max-width: 100%;
    width: fit-content
}

.textContainer {
    margin-left: 20px;
    max-width: 100%;
    overflow: hidden;
}


@media (max-width: 450px) {
    .nowrap .name {
        white-space: unset
    }
}
